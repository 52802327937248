@import url('https://fonts.googleapis.com/css2?family=Merriweather&family=Noto+Serif:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Merriweather&family=Noto+Serif:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
    html{
        font-family: "Merriweather", serif;
       

    }
}
